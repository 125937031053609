import React from 'react'
import { Container, Box, Typography, TextField, Button, MenuItem, } from '@mui/material'
import { Link } from 'react-router-dom';

const TextFieldStyle = {
    width: "100%",
    "& .MuiOutlinedInput-root": {
        background: '#EEEEEE',
        borderRadius: '8px',
        borderColor: 'none'
        // "& fieldset": {
        //     borderRadius: `16px`,
        //     border: 'none',
        //     boxShadow: "inset 0px 2px 2px rgba(105, 105, 105, 0.25)"
        // },
    },
}
const btnStyle = { padding: '10px 50px', background: '#323263', '&:hover': { background: '#323263' } }

const Login = () => {
    return (
        <>
            <Box className="hero-bg">
                <Container maxWidth="md" fixed sx={{ paddingTop: '50px', paddingBottom: '100px' }}>
                    <Typography fontSize="40px" textAlign="center" lineHeight="47px" fontWeight="bold" color="#fff" padding="10px">
                        Login
                    </Typography>
                    <br />
                    <Container fixed maxWidth="sm" sx={{ background: '#fff', borderRadius: '18px' }}>
                        <Box sx={{ padding: '30px', }}>
                            <form>
                                <Box sx={{ marginTop: '15px' }}>
                                    <Typography fontSize="22px" lineHeight="36px">
                                        User Id
                                    </Typography>
                                    <TextField
                                        sx={TextFieldStyle}
                                    />
                                </Box>
                                <Box sx={{ marginTop: '15px', position: 'relative' }}>
                                    <Typography fontSize="22px" lineHeight="36px">
                                        Password
                                    </Typography>
                                    <TextField
                                        sx={TextFieldStyle}
                                    />
                                    <Typography fontSize="18px" right="0" lineHeight="36px" position="absolute">
                                        Forgot Password?
                                    </Typography>
                                </Box>
                                <Box sx={{ marginTop: '15px' }}>
                                    <Typography fontSize="22px" lineHeight="36px">
                                        Where to
                                    </Typography>
                                    <TextField
                                        sx={TextFieldStyle}
                                        select
                                    >
                                        <MenuItem value="Retailer">
                                            Retailer
                                        </MenuItem>
                                        <MenuItem value="Distributor">
                                            Distributor
                                        </MenuItem>
                                        <MenuItem value="Admin">
                                            Admin
                                        </MenuItem>
                                        <MenuItem value="C&E">
                                            C & E
                                        </MenuItem>
                                        <MenuItem value="S.S">
                                            S.S
                                        </MenuItem>
                                    </TextField>
                                </Box>
                                <br />
                                <br />
                                <Box textAlign="center">
                                    <Button sx={btnStyle} variant="contained" color="primary" size="large">
                                        Login
                                    </Button>
                                </Box>
                            </form>
                            <br />
                            <Typography fontSize="18px" textAlign="center" lineHeight="36px">
                                Don’t have an account? <Link to="/register">Signup</Link>
                            </Typography>
                        </Box>
                    </Container>
                </Container>
            </Box>
        </>
    )
}

export default Login
