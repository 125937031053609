import React from 'react'
import { Container, Box } from '@mui/material/'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Fade from 'react-reveal/Fade';

const About = () => {
    const cardStyle = {
        border: "1px solid #FFFFFF",
        borderRadius: "26px",
        boxSizing: "border-box",
        padding: '20px',
        width: '100%'
    }
    return (
        <>
            <Box className='full' sx={{ marginTop: '-3px', padding: { md: 'auto', xs: '50px 10px' } }}>
                <Container fixed sx={{ minHeight: '100vh' }}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                    >
                        <Grid item xl="6" md="6" xs="12">
                            <Fade bottom>
                                <Typography fontSize="40px" lineHeight="47px" fontWeight="bold" color="#fff" padding="10px">
                                    ABOUT US
                                </Typography>
                                <Typography fontSize="24px" lineHeight="36px" color="#fff" padding="10px">
                                The aim of our company is to provide the best quality products and service to our customers. Our company wants to solve business problems with the help of technology
                                </Typography>
                            </Fade>
                            {/* <br /><br />
                            <Fade bottom>
                                <Typography fontSize="40px" lineHeight="47px" fontWeight="bold" color="#fff" padding="10px">
                                    OUR COMPANY
                                </Typography>
                                <Typography fontSize="24px" lineHeight="36px" color="#fff" padding="10px">
                                    The aim of our company is to provide the best quality products and service to our customers. Our company wants to solve business problems with the help of technology
                                </Typography>
                            </Fade> */}
                        </Grid>
                        <Grid item xl="5" md="5" xs="12">
                            <Fade bottom>
                                <img src={require("../assets/shield-5274069-4404363 1.png")} alt="" style={{ width: '100%' }} />
                            </Fade>
                        </Grid>
                    </Grid>
                    <br /><br />
                    <Box display="flex" gap="50px" sx={{ flexWrap: { md: 'nowrap', xs: 'wrap' } }}>
                        <Box sx={cardStyle}>
                            <Typography fontSize="32px" lineHeight="38px" fontWeight="bold" color="#fff" padding="10px">
                                Vision
                            </Typography>
                            <Typography fontSize="24px" lineHeight="36px" color="#fff" padding="10px">
                                We are engaged in making our creative and unique ideas a reality, as well as trying to solve common and rare problems of the people with the help of technology.
                            </Typography>
                        </Box>
                        <Box sx={cardStyle}>
                            <Typography sx={{ display: 'inline-block', }} fontSize="32px" lineHeight="38px" fontWeight="bold" color="#fff" padding="10px">
                                Mission
                            </Typography>
                            <Typography fontSize="24px" lineHeight="36px" color="#fff" padding="10px">
                                Online business growth ratio in India is worthy of praise  in itself, but there are many businessmen who are not able to come online, we want to provide a business platform for them.
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default About
