import React from 'react'
import { Container, Box } from '@mui/material/'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Fade from 'react-reveal/Fade';

const Products = () => {
    return (
        <Box  >
            <Box>
                <Container fixed>
                    <br/> <br/> <br/>
                    <Typography fontSize="40px" lineHeight="47px" fontWeight="bold" color="#fff" padding="10px">
                    OUR PRODUCTS
                    </Typography>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                        sx={{ minHeight: '100vh' }}
                    >
                        <Grid item xl="6" md="6" xs="12">
                            <Fade bottom>
                                <Typography fontSize="40px" lineHeight="47px" fontWeight="bold" color="#8A56C6" padding="10px">
                                    Anti Theft
                                </Typography>
                                <Typography fontSize="24px" lineHeight="36px" color="#fff" padding="10px">
                                    Oak Mount Technology India Pvt Ltd presents Saarthi Anti Theft Mobile Application designed for your safety and your loved ones' safety.  Sarathi Anti Theft is designed keeping your safety in mind.  It gives you more than 20 security features.  It keeps your mobile and your personal data safe in it, as well as it protects you.  Your mobile stays automatically with all your information, what will you do if it is stolen. Don't be afraid  Now with just one click or send a message, you can completely lock your mobile, immediately after locking it will send you the thief's photo and his current location as well as it will turn on the loud siren so that the people nearby  Will also be aware.
                                </Typography>
                            </Fade>
                        </Grid>
                        <Grid item xl="4" md="4" xs="12">
                            <Fade bottom>
                                <img src={require("../assets/Group 4.png")} alt="" style={{ width: '100%' }} />
                            </Fade>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {/* <img src={require("../assets/pngwing 5.png")} alt="" style={{ width: '100%' }} /> */}
            <Container fixed>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="center"
                    flexWrap="wrap-reverse"
                    sx={{ minHeight: '100vh' }}
                >
                    <Grid item xl="5" md="5" xs="12">
                        <Fade bottom>
                            <img src={require("../assets/Group 5.png")} alt="" style={{ width: '100%' }} />
                        </Fade>
                    </Grid>
                    <Grid item xl="6" md="6" xs="12">
                        <Fade bottom>
                            <Typography fontSize="40px" lineHeight="47px" fontWeight="bold" color="#FF9337" padding="10px">
                                Human Safety
                            </Typography>
                            <Typography fontSize="24px" lineHeight="36px" color="#fff" padding="10px">
                                The speed of crime is increasing day by day.  No one should be a victim of this environment.  In today's era, women workers are touching new heights with their efficiency.  Whether to do day-night shift in metro city or to handle your own business.  But sometimes the wrong taxi or on deserted roads, anti-social elements come in their way.  Keeping all these conditions in mind we have developed human security.  With the help of a single click, you can send your location to your family.
                            </Typography>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
            {/* <img src={require("../assets/curved.png")} alt="" style={{ width: '100%' }} /> */}
            <Box className="full2">
                <Container fixed>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                        sx={{ minHeight: '100vh' }}
                    >
                        <Grid item xl="6" md="6" xs="12">
                            <Fade bottom>
                                <Typography fontSize="40px" lineHeight="47px" fontWeight="bold" color="#47E2A8" padding="10px">
                                    Sarthi EMI Locker App
                                </Typography>
                                <Typography fontSize="24px" lineHeight="36px" color="#fff" padding="10px">
                                    Mobile retailers provide finance facilities for their customers, either the retailer finances the customers from a finance company or they also do personal finance.  There are many customers who get mobile finance, but they start making different excuses when it comes to EMI.  After this the trouble of mobile retailers starts.  Keeping all this in view, we have prepared for you by bringing Sarathi EMI.  Now whenever the customer does not pay the EMI, the retailer can completely lock that finance mobile by going to their panel, due to the lock the customer is unable to access the mobile.
                                </Typography>
                            </Fade>
                        </Grid>
                        <Grid item xl="4" md="4" xs="12">
                            <Fade bottom>
                                <img src={require("../assets/investment-growth-5328519-4445726 1.png")} alt="" style={{ width: '100%' }} />
                            </Fade>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box >
    )
}

export default Products
