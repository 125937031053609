import React from 'react'
import About from '../components/About';
import Feature from '../components/Feature';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Products from '../components/Products';

const Home = () => {
    return (
        <div>
            <Hero />
            <About />
            <Products />
            <Feature />
            <Footer />
        </div>
    )
}

export default Home
