import { Box, Button } from '@mui/material'
import React from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const Download = () => {
    return (
        <>
            <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <a href="https://firebasestorage.googleapis.com/v0/b/sarthi-af065.appspot.com/o/apk%2Fsarthi.apk?alt=media&token=f0ed8730-e67c-4ff3-8393-8e21037f4eb4" style={{ textDecoration: 'none', }} rel="noreferrer" target="_blank">
                    <Button endIcon={<FileDownloadIcon />} variant="contained" color="primary" size="large">
                        Download
                    </Button>
                </a>
            </Box>
        </>
    )
}

export default Download
