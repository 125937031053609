import React from 'react'
import { Container, Box } from '@mui/material/'
import Typography from '@mui/material/Typography'

const Footer = () => {
    return (
        <>
            <Box>
                <Container fixed>
                    <Typography textAlign="center" fontSize="24px" lineHeight="28px" fontWeight="bold"
                        color="#fff" padding="50px">
                        Contact Us +91 7566337766
                    </Typography>
                </Container>
            </Box>
        </>
    )
}

export default Footer
