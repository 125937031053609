import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import './App.css';
import Home from './ui/pages';
import { useLayoutEffect } from 'react'
import Login from "./ui/pages/Login";
import Register from "./ui/pages/Register";
import Download from "./ui/components/Download";

// scroll to top
const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}


function App() {
  return (
    <div>
      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/download" element={<Download />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
