import React from 'react'
import { Container, Box } from '@mui/material/'
import Typography from '@mui/material/Typography'
import Fade from 'react-reveal/Fade';


const cardStyle = {
    boxSizing: 'border-box',
    padding: '50px 20px',
    background: 'linear-gradient(90deg, #1D2341 38.41%, #2C3253 110.41%)',
    borderRadius: '8px',
    width: '336px',
    height: '278px'
}
const Feature = () => {
    return (
        <>
            <Box className="feature-bg" sx={{ paddingTop: '100px ', paddingBottom: '100px' }}>
                <Container fixed>
                    <Typography textAlign="center" fontSize="40px" lineHeight="47px"
                        fontWeight="bold" color="#fff">
                        OUR FEATURES
                    </Typography>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Box sx={{ display: 'flex', gap: '50px', flexWrap: 'wrap', justifyContent: 'center' }}>
                        {data.map((item) => (<>
                            <Fade bottom>
                                <Box sx={cardStyle}>
                                    <div>
                                        <img src={require(`../assets/${item.url}`)} alt="" />
                                    </div>
                                    <br />
                                    <Typography gutterBottom fontSize="24px" lineHeight="28px" fontWeight="bold" color="#fff" >
                                        {item.title}
                                    </Typography>
                                    <Typography fontSize="16px" lineHeight="19px" color="#fff" >
                                        Get a live location update of your mobile in case of emergency or mobile loss
                                    </Typography>
                                </Box>
                            </Fade>
                        </>))}
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default Feature

const data = [
    {
        title: "Live Location",
        url: "location-svgrepo-com 1.png"
    },
    {
        title: "SIM Card Lock",
        url: "sim-card-svgrepo-com 1.png"
    },
    {
        title: "Women Safety",
        url: "women-svgrepo-com 1.png"
    },
    {
        title: "Child Activity",
        url: "girl-child-svgrepo-com 1.png"
    },
    {
        title: "Mobile Protection",
        url: "Mobile Protection.png"
    },
    {
        title: "Movement Lock",
        url: "Movement Lock.png"
    },
    {
        title: "Photo Capture",
        url: "Photo Capture.png"
    },
    {
        title: "App’s Safety",
        url: "App’s Safety.png"
    },
    {
        title: "Don’t Touch Phone",
        url: "Don’t Touch Phone.png"
    },
    {
        title: "Don’t Switch Off",
        url: "Don’t Switch Off.png"
    },
    {
        title: "Wrong Lock Alarm",
        url: "Wrong Lock Alarm.png"
    },
    {
        title: "Silent Lock",
        url: "Silent Lock.png"
    },
]
