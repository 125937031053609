import React from 'react'
import { Box, Typography, Container, } from '@mui/material'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';

const parentStyle = {
    boxSizing: 'border-box',
    textAlign: 'center',
    // paddi ng: { md: '10px 0px', xs: '10px', }
}
const Hero = () => {
    return (
        <>
            <Box className="hero-bg">
                <Box sx={parentStyle}>
                    <Container fixed >
                        <Box display="flex" justifyContent="space-between" sx={{ py: '20px', alignItems: 'center' }}>
                            <Box sx={{ width: { md: '150px', xs: '100px' } }}>
                                <img src={require("../assets/logo.png")} alt="" style={{ width: '100%' }} />
                            </Box>
                            <Box sx={{ width: { md: '200px', xs: '150px' } }}>
                                <a href="https://dashboard.oakmounts.co.in/login" target='_blank'>
                                    <img src={require("../assets/loginbtn.svg").default} alt="" style={{ width: '100%', cursor: 'pointer ' }} />
                                </a>
                            </Box>
                        </Box>
                        <br />
                    </Container>
                    <Box sx={{ background: '#323263' }}>
                        <Typography fontSize="24px" lineHeight="36px" color="#fff" padding="10px">
                            Protect every human being and the data being used by them. <a href="/" style={{ color: 'red' }}>GET IN TOUCH</a>
                        </Typography>
                    </Box>
                </Box>
                <Container fixed sx={{ textAlign: 'center' }}>
                    <Fade bottom>
                        <img src={require("../assets/newmockup2.png")} alt="" style={{ width: "100%",marginBottom:'-2px' }} />
                    </Fade>
                </Container>
            </Box>
        </>
    )
}

export default Hero
